<template>
    <div class="course-List">
        <head-img desc="马术课程" :imgUrl="courseBgUrl"></head-img>
        <my-menu ref="menu" :titleList="titleList" :menuList="menuList" @changeTab="changeTab"></my-menu>
        <div class="course-content">
            <div class="content">
                <div class="content-course">
                    <div class="news-student" v-if="active === 0">
                        <div class="text" v-html="information.teachingContent"> </div>
                    </div>
                    <div class="news-student" v-if="active === 1">
                        <div class="text" v-html="information.teachingContent"> </div>
                    </div>
                    <div class="elegant" v-if="active === 2">
                        <div class="text" v-html="information.teachingContent"> </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            courseBgUrl: require('@/assets/img/home/lunbo6.jpg'),
            menuList: [
                {name: '首页', path: '/'},
                {name: '马术课程'},
            ],
            titleList: [ ],
            imgList: [
                {url: require('@/assets/img/coach/coach1.jpg')},
                {url: require('@/assets/img/coach/coach2.jpg')},
                {url: require('@/assets/img/coach/coach3.jpg')},
                {url: require('@/assets/img/coach/coach4.jpg')},
            ],
            page: {
                current: 1,
                size: 5,
            },
            model: {
                status: 1, 
                websiteFlag: 1
            },
            active: 0,
            id: '',
            information: {}

        }
    },
    watch:{
        $route(to){
            console.log(to.path)
            if(to.path === '/course'){
                console.log(this.$route.query.id)
                // this.id = this.$route.query.id
                // this.getDetail()
                this.$refs.menu.setActive(this.$route.query.type-0);
                this.active = this.$route.query.type-0
            }
        }
    },
    methods:{
        changeTab(data) {
            this.active = data
            // console.log(this.titleList[data].id)
            this.id = this.titleList[data].id
            this.getDetail()
        },
        getCourseList() {
            this.$api.post('base/curriculum/curriculum-listForHomePage', {page: this.page, model: this.model}).then(res=>{
                res.data.records.forEach(ele=>{
                    this.titleList.push({
                        name: ele.curriculumName,
                        id: ele.id
                    })
                })
            })
        },
        getDetail() {
            this.$store.commit('loading', '.course-List .course-content')
            this.$api.post('base/curriculum/curriculum-showForHomePage', {id: this.id}).then(res=>{
                //console.log(res)
                this.information = res.data
            })
            .finally(()=>{
                this.$store.commit('loaded')
            })
        }

    },
    mounted() {
        this.id = this.$route.query.id
        this.getCourseList()
        // console.log(this.$route.query.type)
        if(this.$route.query.type === '1'){
           this.$refs.menu.setActive(1);
           this.active = 1
        }
        if(this.$route.query.type === '2'){
           this.$refs.menu.setActive(2);
           this.active = 2
        }
        this.$refs.menu.setValue(true);
        this.getDetail()
    }
}
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    .course-List{
        .course-content{
            display: flex;
            justify-content: center;
            align-items: center;
            .content{
                width: 1200px;
                &-course{
                    margin: 50px 0;
                    .news-student{
                       .title{
                           font-size: 32px;
                           color: #262626;
                           padding-bottom: 20px;
                           text-align: center;
                       }
                       .text{
                           line-height: 30px;
                           .bold{
                               font-size: 16px;
                               font-weight: bold;
                           }
                            p{
                                text-indent: 1cm
                            }
                       }
                    }
                    .elegant{
                        .img-list{
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            .item{
                                margin-bottom: 30px;
                                border-radius: 9px;
                                overflow: hidden;
                                width: 445px;
                                height: 302px;
                                .img{
                                    width: 445px;
                                    height: 100%;
                                }
                                transition: all 0.3s;
                                &:hover{
                                    transform: scale(1.08);
                                    cursor: pointer;
                                    box-shadow: 0 0 6px 3px #ccc;
                                }
                                &:last-child{
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

</style>